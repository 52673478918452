/* You can add global styles to this file, and also import other style files */
@import 'ckeditor5/ckeditor5.css';
@import './scss/site.scss';
@import "../node_modules/angular-calendar/css/angular-calendar.css";

:root .lpx-theme-dark {
  --lpx-logo: url('/assets/images/logo/logo-light.svg');
  --lpx-logo-icon: url('/assets/images/logo/icon.svg');

  .abp-support {
    background-image: url('/assets/images/getting-started/abp-support-white.svg');
  }
  .abp-community {
    background-image: url('/assets/images/getting-started/abp-community-white.svg');
  }
  .abp-blog {
    background-image: url('/assets/images/getting-started/abp-blog-white.svg');
  }
  .logo-x {
    background-image: url('/assets/images/getting-started/x-white.svg');
  }
}

:root .lpx-theme-light {
  --lpx-logo: url('/assets/images/logo/h_color.png');
  --lpx-logo-icon: url('/assets/images/logo/h_color.png');
}

:root {
  --lpx-theme-light-bg: url('/assets/images/login/login-bg-img-light.svg');
  --lpx-theme-dim-bg: url('/assets/images/login/login-bg-img-dim.svg');
  --lpx-theme-dark-bg: url('/assets/images/login/login-bg-img-dark.svg');

  .abp-logo {
    background-repeat: no-repeat;
  }

  .abp-support {
    width: 178px;
    height: 30px;
    background-image: url('/assets/images/getting-started/abp-support.svg');
  }

  .abp-community {
    width: 215px;
    height: 30px;
    background-image: url('/assets/images/getting-started/abp-community.svg');
  }

  .abp-blog {
    width: 142px;
    height: 30px;
    background-image: url('/assets/images/getting-started/abp-blog.svg');
  }

  .logo-x {
    width: 20px;
    height: 20px;
    background-image: url('/assets/images/getting-started/x.svg');
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
